import React from 'react'
import PropTypes from 'prop-types'

const Testimonials = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testimonialIndex: 0,
      intervalId: 0,
      height: 0,
      numTestimonials: 0
    }
    this.goUp = true
    this.surroundingDiv = React.createRef()
    this.autoMove = this.autoMove.bind(this)
    this.changeIndex = this.changeIndex.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.doMinHeight = this.doMinHeight.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.setState({ numTestimonials: this.props.testimonials.length })
    if (this.state.intervalId !== 0) {
      clearInterval(this.state.intervalId)
    }
    for (let index = 0; index < this.props.testimonials.length; index++) {
      this.setState({ testimonialIndex: 0 })
    }
    const intervalId = setInterval(this.autoMove, 5000)
    this.setState({ intervalId: intervalId })
  }

  componentDidUpdate(prevProps) {
    if (this.props.width === prevProps.width) {
      this.doMinHeight()
    } else {
      this.handleResize(null)
    }
    
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  autoMove() {
    const goUp = this.goUp
    if ((this.state.testimonialIndex + 1 >= this.state.numTestimonials) && (goUp === true)) {
      this.setState({ testimonialIndex: 0 })
    } else if (goUp === true) {
      this.setState({ testimonialIndex: Math.min(this.state.numTestimonials - 1, this.state.testimonialIndex + 1) })
    } else if (this.state.testimonialIndex === 0) {
      this.setState({ testimonialIndex: this.state.numTestimonials - 1 })
    } else {
      this.setState({ testimonialIndex: Math.max(0, this.state.testimonialIndex - 1) })
    }
  }

  changeIndex(up) {
    clearInterval(this.state.intervalId)
    this.goUp = up
    this.autoMove()
  }

  handleResize(e) {
    this.setState({ height: 0 })
  }

  doMinHeight() {
    if (this.surroundingDiv !== undefined) {
      const rectH = this.surroundingDiv.current.getBoundingClientRect().height
      if (rectH > this.state.height) {
        this.setState({ height: rectH })
      }
    }
  }

  render() {
    return this.props.testimonials === null ||
      this.props.testimonials === undefined ? null : (
      <div className="grid gai-center w-100 testimonial-grid">
        <div className="gcs1 gce2 grs1 gre2">
          <div onClick={() => { this.changeIndex(false) }} className="pointer grow f1 fw1">{`${'<'}`}</div>
        </div>
        <div className="gcs2 gce3 grs1 gre2 grid" ref={this.surroundingDiv} style={{ minHeight: this.state.height }}>
          <article className="tc gjs-center self-center ">
            <div className="pb3 self-center ph5-ns ph3">
              {this.props.testimonials[this.state.testimonialIndex].quote}
            </div>
            <cite className="fw5">
              {
                this.props.testimonials[this.state.testimonialIndex]
                  .companydetails
              }
            </cite>
          </article>
        </div>
        <div className="gcs3 gce4 grs1 gre2">
          <div onClick={() => { this.changeIndex(true) }} className="pointer grow f1 fw1">{`${'>'}`}</div>
        </div>

      </div>
    )
  }
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string
    })
  )
}

export default Testimonials
