import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, Script } from 'gatsby'
import PreviewCompatibleImage from './../components/PreviewCompatibleImage'

import Layout from '../components/Layout'

import homeBackground from '../img/Home4.jpg'
import goldBg from '../img/goldbg.jpg'

import Markdown from 'markdown-to-jsx'
import LogoLinkGrid from '../components/LogoLinks'
import Testimonials from '../components/Testimonials'

import axios from 'axios'

const isEmtpy = (a) => {
  return a === null || a === undefined
}

const SideSwipeHeading = ({ heading }) => (
  <div className="grid gc-stripy w-100 pv4 ph5-ns ph3 f4 ski-medium-silver tracked">
    <div className="gcs1 gce2 grs1 gre2 bb bw2 pl4 db-ns dn"></div>
    <div className="gcs2 gce3 grs1 gre2 ttu mh4-ns mh2 fw6">{heading}</div>
    <div className="gcs3 gce4 grs1 gre2 bb bw2 pr4 db-ns dn"></div>
  </div>
)

export const IndexPageTemplate = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletterdataemail: '',
      newsletterdataname: '',
      isTop: true,
      width: 0
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    }

    const apibody = {
      buttonlabel: 'Newsletter Signup',
      form: {
        name:
          this.state.newsletterdataname === null ||
            this.state.newsletterdataname === undefined
            ? 'Newsletter subscriber'
            : this.state.newsletterdataname,
        email: this.state.newsletterdataemail,
        message: 'Please add me to your email newsletter list',
      },
    }

    if (/\S+@\S+\.\S+/.test(apibody.form.email)) {
      axios
        .post(
          'https://europe-west1-curated-stores.cloudfunctions.net/contactEmail?contact=lenaskidynamic',
          apibody
          // ,axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({ newsletterdataemail: '', newsletterdataname: '' })
          }
        })
        .catch((err) => {
          console.log('Message sending failed')
          console.warn(err)
        })
    }
  }

  handleChange = (key, event) => {
    if (key === 'name') {
      this.setState({ newsletterdataname: event.target.value })
    } else if (key === 'email') {
      this.setState({ newsletterdataemail: event.target.value })
    }
  }

  handleScroll = (event) => {
    try {
      this.setState({
        isTop: event.srcElement.lastElementChild.scrollTop < 5,
      })
    } catch (error) {
      this.setState({
        isTop: true,
      })
    }
  }

  handleResize = (e) => {
    this.setState({ width: window.width })
  }

  render() {
    return (
      <div>

        {/* profilepicture & primaryheadline */}
        <div className="w-100 gc2 grid">
          <div
            className="gcs1 gce3 grs1 gre2 z-1 bg-100wh cover"
            style={{ backgroundImage: `url(${homeBackground})` }}
          ></div>
          {/* change mw for max width */}
          <div className="gcs2-ns gcs1 gce3 grs1-ns gre2-ns grs1 gre2 mw6-l mw5-m ml6-l ml0-m pl0-m pr0-ns pl2-ns ph4 pt6-l pt5-m pt3 z-2">
            {/* Lena photo */}
            <div className="ph0-ns ph3 pr0">
              <PreviewCompatibleImage
                imageInfo={{
                  image: this.props.profilepicture.image,
                  alt: this.props.profilepicture.alt,
                }}
              />
            </div>
          </div>
          <div className="gcs1 gce3 gce2-ns grs1-ns gre2-ns grs2 gre3 pt6-l pt5-m pt3 pb0-ns pb3 z-2">
            <div className="w-100 pt0 pt5-l pt4-m pl6-ns pr0-ns pl5-m pl7-ns ph4 pb3-ns fw4 f4-ns f5 f5-m">
              {/* <div
                className={
                  'pl5 pr0 pb4 nr2 pl6-ns db-ns dn ' +
                  (this.state.isTop ? '' : 'o-0-ns')
                }
              > */}
              {/* Logo, commenting out for now */}
              {/* <PreviewCompatibleImage
                  imageInfo={{
                    image: this.props.mainlogo.image,
                    alt: this.props.mainlogo.alt,
                  }}
                /> */}
              {/* </div> */}

              <div className="tl-ns tc pl6-m f4-ns f5-m f5 pt4 pt0-ns tracked ski-white-ns ttc">
                {this.props.primaryheadline.title}
              </div>
              <br />
              <div className="tl-ns tc pl6-m fw6 fw5-m f2-l f3-m f3 tracked ski-white-ns ttc">
                {this.props.primaryheadline.highlight}
              </div>
              <br />
              <div className="tl-ns tc pl6-m pb4-ns pb2 ttc fw2-ns fw3 f6-m f4-l ski-white-ns">
                <Markdown>{this.props.primaryheadline.description}</Markdown>
              </div>
            </div>
          </div>
        </div>

        {/* secondaryheadline */}
        {/* <div className="tc pt2 pb4 ph7-ns ph6-m ph2 fw5 f4-ns f5-m f5 lh-copy tracked ski-dark-blue-ns">
          <Markdown>{this.props.secondaryheadline}</Markdown>
        </div> */}

        {/* // invitationheading  */}
        <div className="tc pt4 pb3 ph7-ns ph5-m ph4 fw3 f4-ns f5 f5-m lh-copy tracked ski-dark-blue pt3-markup">
          <div className="tracked ttc fw6 f4 f3-ns">
            {this.props.invitationheading.heading}
          </div>
          <div className="f2-l f2-ns f3 tracked">
            {this.props.invitationheading.subheading}
          </div>
        </div>

        {/* // Invitation Boxes  */}
        <div className="gafc grid tc pt2-ns pb2 pb4-ns ph6-l ph2-sm-i ph3 gc3 gafr ">
          {/* l top */}
          <div className="gcs1 gce2-ns gce4 grs1 gre2 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.invitationboxes.leftimage_pb,
                alt: this.props.invitationboxes.leftalt,
              }}
            />
          </div>
          {/* l bottom */}
          <div className="gcs1 gce2-ns gce4 grs2 gre3 ph4-l ph2-m ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt3-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3-l ph2-m ph3 dc-sm-i">
              <Markdown>{this.props.invitationboxes.leftdescription}</Markdown>
            </div>
          </div>
          {/* ml top */}
          <div className="gcs2-ns gcs1 gce3-ns gce4 grs1-ns grs3 gre2-ns gre4 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.invitationboxes.middleimage,
                alt: this.props.invitationboxes.middlealt,
              }}
            />
          </div>
          {/* ml bottom */}
          <div className="gcs2-ns gcs1 gce3-ns gce4 grs2-ns grs4 gre3-ns gre5 ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt3-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3 dc-sm-i">
              <Markdown>{this.props.invitationboxes.middledescription}</Markdown>
            </div>
          </div>
          {/* r top */}
          <div className="gcs3-ns gcs1 gce4 grs1-ns grs7 gre2-ns gre8 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.invitationboxes.rightimage,
                alt: this.props.invitationboxes.rightalt,
              }}
            />
          </div>
          {/* r bottom */}
          <div className="gcs3-ns gcs1 gce4 grs2-ns grs8 gre3-ns gre9 ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt3-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3 dc-sm-i">
              <Markdown>{this.props.invitationboxes.rightdescription}</Markdown>
            </div>
          </div>
        </div>

        {/* textblock: uncover your calling */}
        <div className="ph0-ns ph2 pb0-ns pb2">
          <div className="tc pt4-ns pv4 ph6-ns ph5-m ph3 bg-ski-light-blue grid gc3">
            <div className="white-70 f2-l f3 fw6 gcs1 gce2-ns gce4 grs1 gre2 pr4-ns self-center lh-copy lh-solid tracked">
              <Markdown>{this.props.textblock.title}</Markdown>
            </div>
            <div className="gcs2-ns gcs1 gce4 grs1-ns grs2 gre2-ns gre3 pl4-ns tj-ns tc fw4 f4-ns f5-m f5 lh-copy tracked pv3-ns pv4">
              <span>
                {' '}
                <Markdown>{this.props.textblock.blacktext}</Markdown>
              </span>
            </div>
          </div>
        </div>

        {/* purposepicture */}
        <div className="db-ns dn ph7-l ph5-m pb4 pt4">
          <PreviewCompatibleImage
            imageInfo={{
              image: this.props.purposepicture.image,
              alt: this.props.purposepicture.alt,
            }}
          />
        </div>
        <div className="dn-ns db ph3 pb4">
          <PreviewCompatibleImage
            imageInfo={{
              image: this.props.purposepicture.cellphonepurposeimage,
            }}
          />
        </div>

        {/* introparagraph */}
        <div className="tj-ns tc fw4 f4-ns f5 f5-m lh-copy tracked pt2 pb4 ph5-m ph7-ns ph4">
          <Markdown>{this.props.introparagraph}</Markdown>
        </div>

        {/* conclusionquote */}
        <div className="tc pt3 pb4 ph6-ns ph2-m ph3 lh-copy ski-dark-blue">
          <div className="f2-ns f3 f3-m tracked ski-medium-turquoise cursive">
            {this.props.conclusionquote.quote}
          </div>
          <div>{this.props.conclusionquote.quotecredit}</div>
        </div>

        {/* review with image */}
        <div className="mh6-ns mh3-m mh2 b--ski-medium-turquoise bw1 bl bt br ph5-ns ph2-m ph4 pv3 mb0">
          <div className="w-100 grid-ns gcg3-ns gc3-ns tc tl-ns">
            <div className="gcs1-ns gce2-ns grs1-ns gre2-ns h-100 grid">
              <div className="self-center pt0-ns pt3">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: this.props.reviewimage.image,
                    alt: this.props.reviewimage.alt,
                  }}
                />
              </div>
            </div>
            <div className="gcs2-ns gce4-ns grs1-ns gre2-ns pv3-ns pt4 pb3 ph4-ns mr3-ns self-center">
              <Markdown className="lh-copy fw4 f6">{this.props.reviewimage.description}</Markdown>
            </div>
          </div>
        </div>

        {/* newsletter */}
        <div className="tc pt2 pt4-m pb4 ph6-ns ph4-m ph3 bg-ski-medium-turquoise">
          <div className="grid gc2">
            <div className="white-60 cursive f1 fw2 tracked pt3 gcs2-ns gce3 gcs1 grs1 gre2 pb3 tc">
              {this.props.newsletter.title}
            </div>
            <div className="gcs1 gce2-ns gce3 grs1-ns grs2 gre2-ns gre3 g-self-end f5-m pb2 ph5-ns ph2 ph2-m lh-copy">
              <Markdown>{this.props.newsletter.description}</Markdown>
            </div>
            <form
              className="grid gc2 w-100 gcs1 gce3 grs2-ns grs3 gre3-ns gre4 "
              onSubmit={this.handleSubmit}
            >
              <div className="grid gc2 gcs1 gce2-ns gce3 w-100">
                <div className="gcs1 gce2-ns gce3 grs1 gre2 mh3 mh2-m">
                  <input
                    className="pv2 f5 ma3 pa3 input-reset center lh-solid w-100"
                    type="text"
                    name="contact-name"
                    id="contact-name"
                    placeholder="Name"
                    value={this.state.newsletterdataname}
                    onChange={(event) => {
                      this.handleChange('name', event)
                    }}
                    required
                  />
                </div>
                <div className="gcs2-ns gcs1 gce3 grs1-ns grs2 gre2-ne gre3 mh3 mh2-m">
                  <input
                    className="pv2 f5 ma3 pa3 input-reset center lh-solid w-100"
                    type="email"
                    name="contact-email"
                    id="contact-email"
                    placeholder="email"
                    value={this.state.newsletterdataemail}
                    onChange={(event) => {
                      this.handleChange('email', event)
                    }}
                    required
                  />
                </div>
              </div>

              <div className="w-100 gcs2-ns gcs1 gce3 grs1-ns grs3 gre2-ns gre4 pt0-ns pt3 grid h-100">
                <input
                  type="submit"
                  value={this.props.newsletter.subscribebutton}
                  className="shadow-1 grow pointer link tc fw4 f6 ph4 tracked ph3 pv2 ba dt center self-center black bg-ski-dark-blue b--ski-dark-turquoise"
                />
              </div>
            </form>

            {/* <div className="gcs2-ns gcs1 gce3 grs2-ns grs3 gre3-ns gre4">
              <div className="shadow-1 grow pointer link tc fw4 f6 ph4 tracked ph3 pv2 dt center black bg-ski-dark-blue">
                {newsletter.subscribebutton}
              </div>
            </div> */}
          </div>
        </div>

        {/* experiencestatement: as a purpose strategist... */}
        <div className="tc pt4 pb4 ph6-ns ph5-m ph2">
          <div className="fw6 f4 lh-copy tracked pb3">
            {this.props.experiencestatement.title}
          </div>
          <div className="tj fw3 f4-ns f5 f5-m lh-copy ph6-ns ph2-m ph3">
            <Markdown>{this.props.experiencestatement.blacktext}</Markdown>
            {this.props.experiencestatement.greentext === '' ? null : (
              <span className="ski-medium-turquoise fw6">
                {' '}
                <Markdown>{this.props.experiencestatement.greentext}</Markdown>
              </span>
            )}
          </div>
        </div>

        <div className="w-100 dn-ns db pt3">
          <div className="ph3">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.imagerow.rightimage,
                alt: this.props.imagerow.rightalt,
              }}
            />
          </div>
        </div>

        {/* experienceproblem */}
        <div className="tj pt4 pb4 ph7-ns ph5-m ph4 lh-copy fw3 f4-ns f5 f5-m bg-ski-verylight-silver">
          <div className="pt3-markup">
            <Markdown>{this.props.experienceproblem}</Markdown>
          </div>
        </div>

        {/* experiencesolution: I chose to be brave... */}
        <div className="tc pt4 pb4 ph7-ns ph5-m ph4 fw5 f3-ns f4 f4-m lh-copy tracked-ns bg-ski-light-turquoise">
          <div className="f4-ns f5 f5-m pt3-markup">
            <Markdown>{this.props.experiencesolution.description}</Markdown>
          </div>
          <div className="ski-medium-turquoise pb2 pt3-markup">
            <Markdown>{this.props.experiencesolution.title}</Markdown>
          </div>
        </div>

        <div className="w-100 ph6-ns coaching-grid-ns grid pt3 gcg3">
          <div className="gcs1 gce2 grs1 gre2 mh5-ns ph5-ns mh3-m ph3-m pl3 pr3 pt3-ns pb3-ns z-2 h-100 grid"
            style={{ backgroundImage: `url(${goldBg})` }}
          >
            <div className="self-center-ns db-ns dn v-mid">
              <PreviewCompatibleImage
                imageInfo={{
                  image: this.props.imagerow.leftimage,
                  alt: this.props.imagerow.leftalt,
                }}
              />
            </div>
          </div>
          <div className="db-ns dn gcs2 gce3 grs1 gre2 mr6-ns mr4-m self-center z-2">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.imagerow.rightimage,
                alt: this.props.imagerow.rightalt,
              }}
            />
          </div>
        </div>

        {/* coachingexperience */}
        <div className="tc pt4 pb3 ph7-ns ph5-m ph4 fw3 f4-ns f5 f5-m lh-copy tracked ski-dark-blue pt3-markup">
          <div className="tracked ttc fw6 f4 f3-ns pb3">
            <Markdown>{this.props.coachingexperience.heading}</Markdown>
          </div>
          <Markdown>{this.props.coachingexperience.description}</Markdown>
        </div>

        {/* visionphrase */}
        <div className="tc pt4 pb4 ph0">
          <div className="grid">
            <div className="gcs1 gce2 grs1 gre2 z-1">
              <PreviewCompatibleImage
                imageInfo={{
                  image: this.props.visionphrase.image,
                  alt: 'Vision',
                  imgClass: 'maxh-7',
                }}
              />
            </div>
            <div className="gcs1 gce2 grs1 gre3 ph6-ns ph5-m ph4 z-999 grid justify-center">
              <div className="self-center cursive f1-l f2-m f3 fw5 w-fit-content ski-medium-turquoise pv3-ns ph4 bg-white-40">
                {this.props.visionphrase.phrase}
              </div>
            </div>
          </div>
        </div>

        {/* what you do on the inside */}
        <div className="tc pt4-ns pb4-ns ph7-ns ph5-m ph4 f4-ns f5 f5-m tracked-ns lh-copy bg-ski-verylight-silver-ns">
          <div className="pt3-markup">
            <Markdown>{this.props.visiontext}</Markdown>
          </div>
        </div>

        {/* visionlist: What If... */}
        <div className="ph5 ph4-m pt4-ns pb3">
          <div className="dn db-ns grid gc3 ph4 ph4-m pv4 bg-ski-light-blue ski-dark-turquoise tracked-l tc">
            <div className="gcs2 gce4 grs1 gre2 ph4 f4 fw4 lh-copy pv3">
              <div className="grid gafc pt3">
                <div className="gcs2 gce3 grs1 gre5 pl2">
                  <Markdown>{this.props.visionlist.description}</Markdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* services */}
        <div className="tl mt3 pt4 pb4 ph0-ns ph3 f4-ns f5 f5-m lh-copy bg-ski-verylight-silver">
          <div className="w-100 gc2 services-grid-m grid gai-center gcg3-l">
            <div className="gcs1 gce2-l gce3 grs1 gre2 gcs1-m gce2-m ml6-l mr0-l mh4-m pa4 pa0-m">
              <div className="relative">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: this.props.services.leftimage,
                    alt: this.props.services.leftalt,
                  }}
                />
                <div className="image-bottom-half-border absolute b--ski-medium-turquoise h-50 z-1 bg-transparent" />
              </div>
            </div>
            <div className="gcs1 gcs2-l gce3 grs1-l grs2 gre2-l gre3 gcs2-m gce4-m grs1-m gre2-m ml5-ns ml5-m mr5-ns mr5-m g-self-endz ph0-ns ph3 z-2">
              <div className="ski-medium-turquoise tracked pb2 ttu tl-ns tc fw6">
                {this.props.services.teamheading}
              </div>
              <div className="pb1 fw5 pb3 tracked-ns ski-dark-silver">
                {this.props.services.teamsubheading}
              </div>
              <div className="bl b--ski-medium-turquoise bw1 pl4 fw3 pb2 pt3-2nd-markup">
                <Markdown>{this.props.services.teamdescription}</Markdown>
              </div>
            </div>
            <div className="gcs1 gce2-l gce3 grs2-l gre3-l grs4 gre5 gcs1-m gce3-m grs2-m gre3-m ml6-ns ml3-m ph3 pt5-ns pb4-ns z-2">
              <div className="ski-dark-blue tracked pb2 ttu tl-ns tc fw6">
                {this.props.services.leaderheading}
              </div>
              <div className="pb1 fw5 pb3 tracked-ns ski-dark-silver">
                {this.props.services.leadersubheading}
              </div>
              <div className="bl b--ski-dark-blue bw1 pl4 fw3 pb2 pt3-2nd-markup">
                <Markdown>{this.props.services.leaderdescription}</Markdown>
              </div>
            </div>
            <div className="gcs2-l gcs1 gce3 grs2-l gre3-l grs3 gre4 gcs3-m gce4-m grs2-m gre3-m pb5-m pa5-ns pa4 pa0-m mr6-l ml0-l mh4-m g-self-end z-2">
              <div className="relative">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: this.props.services.rightimage,
                    alt: this.props.services.rightalt,
                  }}
                />
                <div className="image-bottom-half-border absolute b--ski-dark-blue h-50 z-1 bg-transparent" />
              </div>
            </div>
            {/* new serive block image left */}
            <div className="gcs1 gce2-ns gce3 grs3-ns grs5 gre4-ns gre6 gcs1-m gce2-m ml6-l mr0-l mh4-m pa4 pa0-m">
              <div className="relative">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: this.props.services.leftimagepersonal,
                    alt: this.props.services.leftaltpersonal,
                  }}
                />
                <div className="image-bottom-half-border absolute b--ski-medium-turquoise h-50 z-1 bg-transparent" />
              </div>
            </div>
            <div className="gcs1 gcs2-l gce3 grs3-ns grs6 gre4-ns gre7 gcs2-m gce4-m grs3-m gre4-m ml5-ns ml5-m mr5-ns mr5-m g-self-endz ph0-ns ph3 z-2">
              <div className="ski-medium-turquoise tracked pb2 ttu tl-ns tc fw6">
                {this.props.services.personalheading}
              </div>
              <div className="pb1 fw5 pb3 tracked-ns ski-dark-silver">
                {this.props.services.personalsubheading}
              </div>
              <div className="bl b--ski-medium-turquoise bw1 pl4 fw3 pb2 pt3-2nd-markup">
                <Markdown>{this.props.services.personaldescription}</Markdown>
              </div>
            </div>
          </div>
        </div>

        {/* clientlogos */}
        <div className="w-100 ph7-ns ph5-m pb4 ph1">
          <SideSwipeHeading heading="Previous Clients" />
          <LogoLinkGrid gridItems={this.props.clientlogos} />
        </div>

        {/* conclusion */}
        <div className="ph6-ns ph3-m">
          <div className="tc pt2 pb4 ph6-ns ph3-m ph2-m bg-ski-light-blue mt5">
            <div className="ph5-ns ph3-m ph3 nt5">
              <PreviewCompatibleImage
                imageInfo={{
                  image: this.props.conclusion.image,
                  alt: this.props.conclusion.alt,
                }}
              />
            </div>
          </div>

          {/* <div className="white pv3">
            <span className="cursive f1 fw6">Why... </span>
            <span className="fw5 f4">{conclusion.heading}</span>
          </div>
          <div className="ski-medium-turquoise pv3">
            <span className="cursive f1 fw6">How... </span>
            <span className="fw5 f4 wrap lh-copy">{conclusion.subheading}</span>
          </div>
          <div className="pt3-markup">
            <Markdown>{conclusion.description}</Markdown>
          </div> */}
        </div>

        {/* testimonials */}
        <div className="tc mh6-ns mh3-m ph3-ns b--ski-medium-turquoise bw1-ns bl-ns br-ns bb-ns ph6-ns ph3-m pb4-ns pv3 mb4">
          <div className="ski-medium-turquoise tracked fw7 ttu f3 mt3">
            {this.props.testimonials.title}
          </div>
          <div className="w-100 self-center lh-copy">
            <Testimonials
              testimonials={this.props.testimonials.testimonial.filter(
                (t, idx) => idx !== 0
              )}
              width={this.state.width}
            />
          </div>
          <Link
            className="ski-medium-turquoise no-underline tracked fw5 f5 pt6-ns pt3 pb3"
            to="/portfolio"
          >
            View more on the Portfolio Page
          </Link>
        </div>

        {/* podcast */}
        <div className="w-100 podcast-grid grid ph6-ns ph3-m">
          <div className="gcs1 gce2-ns gce3 grs1-ns grs2 gre2-ns gre3 bg-ski-light-blue grid">
            <div className="pt5-ns pt4-m tc-m pt4 z-2 self-center">
              <div className="white-70 fw5 f2-ns f3 tr-ns tc tc-m ph3-m pr5-ns pb5-ns pb4-m pb4">
                {this.props.podcast.title}
              </div>
              <div className="no-hr tl-ns tc-m tc pl5-ns pl4 pr4 pr6-ns pr5-m f4-ns f5 f5-m pb6-ns pb4 pb4-m lh-copy">
                <Markdown>{this.props.podcast.description}</Markdown>
                <Link
                  className="gray no-underline tracked fw5 f5"
                  to="/podcast"
                >
                  Learn more about the podcast
                </Link>
              </div>
            </div>
          </div>
          <div className="gcs2-ns gcs1 gce3 grs1 gre2 g-self-end z-2 self-center grid h-100">
            <PreviewCompatibleImage
              imageInfo={{
                image: this.props.podcast.image,
                alt: this.props.podcast.alt,
                imgClass: 'right-cover-force',
              }}
            />
          </div>
        </div>

        {/* partnerlogos */}
        <div className="w-100 mt2 pb4 ph7-ns ph3-m ph2">
          <SideSwipeHeading heading="Partners" />
          <LogoLinkGrid gridItems={this.props.partnerlogos} />
        </div>
        <div className='w-100 ph6-ns ph3-m'>
          <div className='b--ski-medium-turquoise bw1-ns bt-ns bl-ns br-ns bb-ns w-100'>
            <iframe src="https://www.videoask.com/fogkzvasg"
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              // width="100%"
              height="600px"
              style={{ border: 'none', borderRadius: '0px' }}
              className="w-100"
            >
            </iframe>
          </div>
        </div>
      </div>
    )
  }
}

IndexPageTemplate.propTypes = {
  profilepicture: PropTypes.object,
  profilebackground: PropTypes.object,
  mainlogo: PropTypes.object,
  primaryheadline: PropTypes.object,
  secondaryheadline: PropTypes.string,
  invitationheading: PropTypes.object,
  invitationboxes: PropTypes.object,
  textblock: PropTypes.object,
  purposepicture: PropTypes.object,
  introparagraph: PropTypes.string,
  introblock: PropTypes.object,
  conclusionquote: PropTypes.object,
  reviewimage: PropTypes.object,
  newsletter: PropTypes.object,
  experiencestatement: PropTypes.object,
  experienceproblem: PropTypes.func,
  experiencesolution: PropTypes.object,
  imagerow: PropTypes.object,
  coachingexperience: PropTypes.func,
  clientlogos: PropTypes.array,
  visionphrase: PropTypes.object,
  visiontext: PropTypes.func,
  visionlist: PropTypes.object,
  services: PropTypes.object,
  // conclusion: PropTypes.object,
  conclusion: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.func,
    description: PropTypes.func,
  }),
  testimonials: PropTypes.object,
  podcast: PropTypes.object,
  partnerlogos: PropTypes.array,
  portfoliotestimonials: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      seo={{
        title: 'Lena Ski - Home',
        keywords: [
          `Creating Ecosystems designed to make purpose thrive`,
          `Changing business structures and brand expectations`,
          `RealWork Purpose & TrueBranding Solutions`,
          `Making work that matters`,
          `Companies with green products: Solar energy, recycling, water conservation, eco systems`,
          `Lead with purpose`,
        ],
        description:
          'Supporting green products and sustainability-driven businesses in South Africa. Creating a purpose-centred-ecosystem, designed to help entrepreneurs thrive',
      }}
    >
      <IndexPageTemplate
        profilepicture={frontmatter.profilepicture}
        profilebackground={frontmatter.profilebackground}
        mainlogo={frontmatter.mainlogo}
        primaryheadline={frontmatter.primaryheadline}
        secondaryheadline={frontmatter.secondaryheadline}
        invitationheading={frontmatter.invitationheading}
        invitationboxes={frontmatter.invitationboxes}
        textblock={frontmatter.textblock}
        purposepicture={frontmatter.purposepicture}
        introparagraph={frontmatter.introparagraph}
        introblock={frontmatter.introblock}
        conclusionquote={frontmatter.conclusionquote}
        reviewimage={frontmatter.reviewimage}
        newsletter={frontmatter.newsletter}
        experiencestatement={frontmatter.experiencestatement}
        experienceproblem={frontmatter.experienceproblem}
        experiencesolution={frontmatter.experiencesolution}
        imagerow={frontmatter.imagerow}
        coachingexperience={frontmatter.coachingexperience}
        clientlogos={frontmatter.clientlogos}
        visionphrase={frontmatter.visionphrase}
        visiontext={frontmatter.visiontext}
        visionlist={frontmatter.visionlist}
        services={frontmatter.services}
        conclusion={frontmatter.conclusion}
        testimonials={frontmatter.testimonials}
        podcast={frontmatter.podcast}
        partnerlogos={frontmatter.partnerlogos}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        profilepicture {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        profilebackground {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        mainlogo {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        primaryheadline {
          title
          highlight
          description
        }
        secondaryheadline
        introparagraph
        introblock {
          description
          title
        }
        invitationheading {
          heading
          subheading
        }
        invitationboxes {
          leftimage_pb {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          leftdescription
          middleimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middlealt
          middledescription
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          rightdescription
        }
        textblock {
          blacktext
          greentext
          title
        }
        purposepicture {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          cellphonepurposeimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        conclusionquote {
          quote
          quotecredit
        }
        reviewimage {
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          description
        }
        newsletter {
          description
          subscribebutton
          title
        }
        experiencestatement {
          blacktext
          greentext
          title
        }
        experienceproblem
        experiencesolution {
          description
          title
        }
        imagerow {
          leftalt
          leftimage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          title
        }
        coachingexperience {
          heading
          description
        }
        clientlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 220, quality: 100, grayscale: true) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          link
          name
        }
        visionphrase {
          phrase
          image {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
        visiontext
        visionlist {
          description
        }
        services {
          leaderdescription
          leaderheading
          leadersubheading
          leftalt
          leftimage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          teamdescription
          teamheading
          teamsubheading
          leftimagepersonal {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftaltpersonal
          personaldescription
          personalheading
          personalsubheading
        }
        conclusion {
          alt
          description
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          subheading
        }
        testimonials {
          testimonial {
            companydetails
            quote
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
              }
            }
            alt
          }
          title
        }
        podcast {
          alt
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          title
        }
        partnerlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 220, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          link
          name
        }
      }
    }
  }
`
